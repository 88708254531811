import logo from './../assets/logo.png';
import iLogo from './../assets/i.png';

const Hero = ({mainText , subText}) => {
  return (
    <div className=" mx-2 my-6 md:mx-14 lg:mx-20 md:my-10">
      <div className="flex justify-between  items-center">
        <div className="w-[232px] text-neutral-50 text-2xl font-bold font-sans md:text-4xl md:w-[500px] ">
          {mainText}
        </div>
        <img src={logo} className="h-24 md:h-32" />{' '}
      </div>

      <div className="bg-stone-900 p-1 md:p-4 text-center my-2 gap-2 lg:my-4 md:my-6 flex items-center w-auto rounded-sm ">
        <img src={iLogo} className="h-7" />
        <div className="text-neutral-50 font-sans text-sm md:text-xl lg:text-2xl md:ml-2 lg:ml-4">
          {subText}
        </div>
      </div>
    </div>
  );
};

export default Hero;
