import React from 'react'

const SmallLoader = () => {
  return (
   
    <div class=" animate-spin inline-block w-3 h-3  border-[3px] border-current border-t-transparent text-purple-600 rounded-full dark:text-blue-500" role="status" aria-label="loading">
      <span class="sr-only">Loading...</span>
    </div>
    
  )
}

export default SmallLoader