import React from 'react'

const Loader = () => {
  return (
    <div className='h-full w-full flex justify-center mt-16'>
<div class=" animate-spin inline-block w-14 h-14 md:w-20 md:h-20 lg:w-28 lg:h-28  border-[3px] border-current border-t-transparent text-purple-600 rounded-full dark:text-blue-500" role="status" aria-label="loading">
  <span class="sr-only">Loading...</span>
</div>
</div>
  )
}

export default Loader