import Hero from './Hero'
import LockDetail from './LockDetail'
import { useContext, useEffect, useState } from 'react'
import Loader from './Loader'
import { WalletContext } from './WalletContext'
import { ethers } from 'ethers'
import { CONTRACT_ADDRESS } from '../utils/constants'
import abi from './../utils/abi.json'

const AllLocks = () => {


  const { walletAddress, provider } = useContext(WalletContext)

  const [isLoading, setIsLoading] = useState(walletAddress ? true : false)
  const [locks, setLocks] = useState(null)

  useEffect(() => {
    if(walletAddress){
      fetchLockDetails()}
  }, [])



  const filterLocks = (lock)=>{
    if(parseFloat(lock[1]) > 0){
      return lock;
    }
  }

  const fetchLockDetails = async () => {
    ///
    if (walletAddress && provider) {
      const contract = new ethers.Contract(CONTRACT_ADDRESS, abi, provider)
      const _locks = await contract.getUserLocks(walletAddress);
      const filteredLocks = _locks.filter(filterLocks);
      setLocks(filteredLocks);
      setIsLoading(false);
     
    }else {
      setLocks(null);
      setIsLoading(false);
    }
  }

  return (
    <>
      <Hero
        mainText={'List Of your previous Locks'}
        subText={'You will be using your smart account for unlocking'}
      />
      <div className=' mx-2 my-6 md:mx-14 lg:mx-20 md:my-10'>
        <div className='shadow flex justify-between  my-1 md:my-2 lg:my-3 p-2'>
          <div className=' text-purple-600 md:text-lg lg:text-2xl font-bold font-sans  w-24 md:w-44 lg:w-48 '>
            Token CA
          </div>
          <div className='e text-purple-600 md:text-lg lg:text-2xl font-bold font-sans w-16 md:w-20 lg:w-28'>
            Amount
          </div>
          <div className=' text-purple-600 md:text-lg lg:text-2xl font-bold font-sans  w-28 md:w-36 lg:w-44'>
            Locked Till
          </div>
          <div className=' text-purple-600 md:text-lg lg:text-2xl font-bold font-sans  w-16 md:w-36 lg:w-44'>
            Unlock
          </div>
        </div>

        {isLoading ? <Loader /> : locks?.map( (lock , index) => <LockDetail key={index} lockDetail={lock}  fetchLockDetails={fetchLockDetails}/>)}
        {!walletAddress &&  <div className='text-white text-center md:text-xl lg:text-2xl font-bold font-sans'>Connect Your Wallet</div>}
      </div>
    </>
  )
}

export default AllLocks
