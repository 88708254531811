import { ParticleNetwork, WalletEntryPosition } from '@particle-network/auth'
import { ParticleProvider } from '@particle-network/provider'
import { EthereumGoerli } from '@particle-network/chains'
import {  createContext, useState } from 'react'
import { ethers } from 'ethers'
import { toast } from 'react-toastify'
import ChainEx from '@chainex/chainex-sdk'
import abi from '../utils/abi.json'
import erc20ABI from '../utils/ERC20ABI.json'
import { CONTRACT_ADDRESS , RPC_URL } from '../utils/constants'

export const WalletContext = createContext()

const WalletProvider = ({ children }) => {
  const [walletAddress, setWalletAddress] = useState(null)
  const [loading, setLoading] = useState(false)
  const [client, setClient] = useState(null)
  const [txHash, setTxHash] = useState(null)
  const [isLocking, setIsLocking] = useState(null)
  const [provider, setProvider] = useState(null)



  const intializingSDK = async () => {
    setLoading(true)
    try {
      const chain =
        '0xGS-12c04e82bec385ab1019d8ad3e506ffdbdf4627ce34843a090fbc3d02da85506'

      const particle = new ParticleNetwork({
        projectId: 'c646c59d-ad74-44e9-8277-93acf5a82d98',
        clientKey: 'cSHaWxVxKviTwL2qO3ipAVVmOIFfduyU1XIY0CIz',
        appId: '946d4c26-bf3f-4889-9a0b-fd9dfb5eed2a',
        chainName: EthereumGoerli.name, //optional: current chain name, default Ethereum.
        chainId: EthereumGoerli.id, //optional: current chain id, default 1.
        wallet: {
          //optional: by default, the wallet entry is displayed in the bottom right corner of the webpage.
          displayWalletEntry: true, //show wallet entry when connect particle.
          defaultWalletEntryPosition: WalletEntryPosition.BR, //wallet entry position
          uiMode: 'dark', //optional: light or dark, if not set, the default is the same as web auth.
          supportChains: [{ id: EthereumGoerli.id, name: EthereumGoerli.name }], // optional: web wallet support chains.
          customStyle: {} //optional: custom wallet style
        },
        securityAccount: {
          //optional: particle security account config
          //prompt set payment password. 0: None, 1: Once(default), 2: Always
          promptSettingWhenSign: 1,
          //prompt set master password. 0: None(default), 1: Once, 2: Always
          promptMasterPasswordSettingWhenLogin: 1
        }
      })
      const particleProvider = new ParticleProvider(particle.auth)
      const ethersProvider = new ethers.providers.Web3Provider(particleProvider)
      console.log('Ethers Provider', ethersProvider)

      setProvider(ethersProvider)

      const chainEx = new ChainEx(chain)
      setClient(chainEx)
      const options = {
        chainId: 5, // Required: Replace with the desired chain ID
        rpcUrl: RPC_URL, // Optional: Specify your custom RPC URL if needed
        isSponsored: true
      }
      const smartAccount = await chainEx.initalize(ethersProvider, options)
      console.log('Smart Account', smartAccount)

      setWalletAddress(smartAccount.smartAccountAddress)
      toast.success('Wallet Connected successfully', {
        position: toast.POSITION.BOTTOM_LEFT
      })
      setLoading(false)
    } catch (err) {
      toast.error('Error while connecting wallet', {
        position: toast.POSITION.BOTTOM_LEFT
      })
      setLoading(false)
      console.log('Error', err)
    }
  }

  const connectWallet = async () => {
    if (!walletAddress) {
      intializingSDK()
    }
  }

  const lockToken = async (tokenAddress, lockAmount, lockedUntil) => {
    if (!walletAddress || !tokenAddress || !lockAmount || !lockedUntil) {
      toast.error('Please fill all the details', {
        position: toast.POSITION.BOTTOM_LEFT
      })
    } else {
      setIsLocking(true)
      const id = toast.loading('Locking Tokens...', {
        position: toast.POSITION.BOTTOM_LEFT,
        isLoading: true
      })
      try {
        const _underlyingToken = new ethers.Contract(
          tokenAddress,
          erc20ABI,
          provider
        )

        const decimals = await _underlyingToken.decimals()
        const bal = await _underlyingToken.balanceOf(walletAddress)
        const balanceOfAccount = bal.toBigInt()

        const amountToLock = ethers.utils
          .parseUnits(lockAmount, decimals)
          .toBigInt()

        if (balanceOfAccount < amountToLock) {

            toast.update(id, {
            type: toast.TYPE.ERROR,
            render: 'Insufficient Token Balance',
            position: toast.POSITION.BOTTOM_LEFT,
            isLoading: false,
            autoClose: 2000
          })
          return;
        }

        console.log(balanceOfAccount, amountToLock, lockedUntil, tokenAddress)
        console.log("Wallet Address" , walletAddress);

        /// Approving transaction

        const tokenContract = new ethers.Contract(tokenAddress, erc20ABI)

        /// ----- Tx for login
        const transaction1 = {
          target: tokenAddress,
          data: tokenContract.interface.encodeFunctionData('approve', [
            CONTRACT_ADDRESS,
            amountToLock
          ])
        }

        /// transaction to lock
        
        const lockContract = new ethers.Contract(CONTRACT_ADDRESS, abi)

        const transaction2 = {
          target: CONTRACT_ADDRESS,
          data: lockContract.interface.encodeFunctionData('lock', [
            tokenAddress,
            amountToLock,
            lockedUntil
          ])
        }

        

        const transactions = [transaction1, transaction2]

        const userOpHash = await client.sendUserOpsBatch(transactions)

        console.log('userOpHash', userOpHash.userOpHash)
        setTxHash(userOpHash)

        toast.update(id, {
          type: toast.TYPE.SUCCESS,
          render: 'Tokens locked Successfully...',
          position: toast.POSITION.BOTTOM_LEFT,
          isLoading: false,
          autoClose: 5000
        })
      } catch (error) {
        setIsLocking(false)
        toast.update(id, {
          type: toast.TYPE.ERROR,
          render: 'Something went wrong',
          position: toast.POSITION.BOTTOM_LEFT,
          isLoading: false,
          autoClose: 5000
        })
      }
    }
  }

  // NEED to get underlying address
  const unlockTokens = async (underlying) => {
    try {
      ///------------- Tx to unlock
     
      const lockContract = new ethers.Contract(CONTRACT_ADDRESS, abi)
     
      const trx = {
        target: CONTRACT_ADDRESS,
        data: lockContract.interface.encodeFunctionData('unlock' , [underlying])
      } 
      
      const userOpHash2 = await client.sendUserOp(trx)
      console.log(userOpHash2)
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <WalletContext.Provider
      value={{
        walletAddress,
        connectWallet,
        loading,
        txHash,
        isLocking,
        lockToken,
        unlockTokens,
        provider,
}}
    >
      {children}
    </WalletContext.Provider>
  )
}

export default WalletProvider
