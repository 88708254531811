import {useContext, useState, useEffect} from 'react';
import {WalletContext} from './WalletContext';
import {toast} from 'react-toastify';
import {ethers} from 'ethers';
import erc20ABI from '../utils/ERC20ABI.json';
import Hero from './Hero';
import { Link } from 'react-router-dom';
import SmallLoader from './SmallLoader';

const Lock = () => {
  const [address, setAddress] = useState ('');
  const [amount, setAmount] = useState ('');
  const [lock, setLock] = useState ('');
  const [tokenDetails, setTokenDetails] = useState (null);
  const [loading , setLoading] = useState(false);
  const [buttonDisbale , setButtonDisable] = useState(false);

  const {lockToken, walletAddress, provider } = useContext (WalletContext);

  useEffect (
    () => {
      fetchTokenDetails ();
    },
    [address]
  );

  const fetchTokenDetails = async () => {
    if (isValidEthereumAddress (address)) {
      setLoading(true);
      setTokenDetails(null)
      try {
        const _underlyingToken = new ethers.Contract (
          address,
          erc20ABI,
          provider
        );

        const name = await _underlyingToken.name ();
        const bal = await _underlyingToken.balanceOf (walletAddress);
        const symbol = await _underlyingToken.symbol ();
        const decimals = await _underlyingToken.decimals ();

        const formatedBal = ethers.utils.formatUnits(bal, decimals);

        setTokenDetails ({
          name,
          formatedBal,
          symbol,
        });

        setLoading(false);
      } catch (error) {
        toast.error ('Invalid Token Address', {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setTokenDetails (null);
        setLoading(false)
        return;
      }
    }
  };

  const handleSubmit = e => {
    e.preventDefault ();

    /// Validating the address
    if (!isValidEthereumAddress (address)) {
      toast.error ('Enter Valid Token Address', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }

    if (amount === '0') {
      toast.error ('Amount cannot be 0', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }

    if (lock === '0') {
      toast.error ('Lock duration cannot be 0', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }

  

    // To lock tokens
    lockToken (address, amount, lock * 86400);

    // Unlock tokens
    // unlockTokens();

    // Reset the form fields
    setAddress ('');
    setAmount ('');
    setLock ('');
  };

  const handleAddress = async e => {
    setAddress (e.target.value);
  };

  function isValidEthereumAddress (address) {
    // Ethereum address regular expression
    const ethereumAddressPattern = /^0x[a-fA-F0-9]{40}$/;

    // Check if the address matches the pattern
    return ethereumAddressPattern.test (address);
  }

  return (

    <div>
      <Hero mainText={"Lock your favourite Token"} subText={"You will be using your smart account for locking"}/>
    <div className="mx-2 my-6 md:mx-14 lg:mx-20 md:my-10 bg-stone-900 p-4 md:p-6 lg:p8 rounded-md">

      <form
        autoComplete="off"
        className="flex gap-2 flex-col"
        onSubmit={handleSubmit}
      >
        <div>
          <div className="flex  flex-col gap-2">
            <label
              className="text-white text-lg font-medium font-sans md:text-2xl"
              htmlFor="address"
            >
              Token Address
            </label>
            <label className="text-white text-sm font-medium font-sans md:text-l">
              {loading &&  <SmallLoader /> }
              {tokenDetails && `Token Name :${tokenDetails?.name}`}
            </label>
          </div>
          <br />
          <input
            className="w-full bg-white bg-opacity-5 rounded md:rounded-md lg:rounded-lg p-4 md:p-5 lg:p-63 text-white text-sm md:text-l lg:text-xl"
            type="text"
            id="address"
            value={address}
            onChange={handleAddress}
            required
          />
        </div>

        <div>
          <div className="flex  flex-col gap-2">
            <label
              className="text-white text-lg mt-5 font-medium font-sans md:text-2xl"
              htmlFor="amount"
            >
              Amount
            </label>
            <label className="text-white text-sm font-medium font-sans md:text-l">
            {loading &&  <SmallLoader /> }
              {tokenDetails && `YourBalance : ${tokenDetails.formatedBal} ${tokenDetails.symbol}`}
            </label>
          </div>
          <br />
          <input
            className="w-full bg-white bg-opacity-5 rounded md:rounded-md lg:rounded-lg p-4 md:p-5 lg:p-6  text-white text-sm md:text-l lg:text-xl"
            type="number"
            id="amount"
            value={amount}
            onChange={e => setAmount (e.target.value)}
            required
          />
        </div>
        <div className="mt-4">
          <div className="flex  flex-col gap-2">
            <label
              className="text-white text-lg font-medium font-sans md:text-2xl"
              htmlFor="lock"
            >
              Days{' '}
            </label>
            <label className="text-white text-sm font-medium font-sans md:text-l">
              ( to lock from now )
            </label>
          </div>
          <br />
          <input
            className="w-full bg-white bg-opacity-5 rounded md:rounded-md lg:rounded-lg p-4 md:p-5 lg:p-6  text-white text-sm md:text-l lg:text-xl"
            type="number"
            id="lock"
            min={1}
            value={lock}
            onChange={e => setLock (e.target.value)}
            required
          />
        </div>
        <div className="w-[305px] h-[47px] p-3.5 bg-neutral-50 rounded-[18px] flex self-center justify-center mt-2 md:mt-3 lg:mt-4 items-center">
          <button
            className=" text-neutral-900 text-base font-semibold font-sans w-full"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
         
       <div className='flex justify-center gap-4'> 

      </div> 
    </div>
    </div>
  );
};

export default Lock;
