import { formatEther } from 'ethers/lib/utils';
import React, { useState , useContext, useEffect } from 'react'
import truncateEthAddress from 'truncate-eth-address';
import moment from "moment"
import { WalletContext } from './WalletContext';


const LockDetail = ({lockDetail , fetchLockDetails}) => {


    const [isButtonDisable , setIsButtonDisable] = useState(true)
    const {unlockTokens} = useContext(WalletContext);

    const underlying = lockDetail[0];
    const amount = lockDetail[1].toString();
    const timestamp = parseInt(lockDetail[2].toString());

    const formatedAmount = parseFloat(formatEther(amount)).toFixed(2);

    const dateFormat = (timestamp)=>{
      const date = moment.unix(timestamp).format("DD/MM/YYYY")
      return date;
  }

  const date = dateFormat(timestamp)




    useEffect(()=>{
         checkIfUserCanUnLock();
         fetchLockDetails();
    } , []);


    const checkIfUserCanUnLock = ()=>{
         const currentTimeStamp = parseInt(moment().unix());
         const unlockTimeStamp = (moment.unix(timestamp).unix())
         
         if(currentTimeStamp >= unlockTimeStamp){
          
          setIsButtonDisable(false);
         }
         
    }

    const handleClick = ()=>{
      unlockTokens(underlying)
    }


  return (
<div className="bg-neutral-800 flex justify-between w-full p-4 md:p-4 lg:p-5 m-2 ">
<div className="  text-white text-xs md:text-lg lg:text-2xl font-medium font-sans w-24 md:w-44 lg:w-48 ">{truncateEthAddress(underlying)}</div>
<div className="  text-white text-xs md:text-lg lg:text-2xl font-medium font-sans w-16 md:w-20 lg:w-28">{formatedAmount}</div>
<div className="  text-white text-xs md:text-lg lg:text-2xl font-medium font-sans w-28 md:w-36 lg:w-44">{date}</div>
<button className="  text-white text-xs md:text-sm lg:text-xl font-medium font-sans  bg bg-purple-600 text-center w-16 md:w-36 lg:w-44 rounded-md" 
   disabled={isButtonDisable}
   onClick={handleClick}
 >{isButtonDisable ? "Wait for unlock" : "Unlock"}</button>

</div>
  )
}

export default LockDetail