import {useContext} from 'react';
import {WalletContext} from './WalletContext';
import truncateEthAddress from 'truncate-eth-address';
import { Link } from 'react-router-dom';

const Header = () => {
  const {walletAddress, connectWallet, loading} = useContext (WalletContext);

  const handleClick = () => {
    if (!walletAddress) {
      connectWallet ();
    }
  };

  return (
    <div className="bg-zinc-900 p-6 flex justify-between">
      <Link to={"/"}>
      <div className="text-neutral-50 text-2xl font-bold font-sans lg:text-4xl">
        ShieldEx
      </div>
      </Link>

      <div className='flex gap-1 md:gap-2 lg:gap-4'>

        {
         walletAddress &&       <Link to={"/locks"} >
         <div className='text-neutral-50 text-xs md:text-lg font-bold font-sans bg-purple-600 p-3 rounded-md'>
           view locks
         </div>
       </Link>
        }



      <button
        onClick={handleClick}
        className="text-neutral-50 text-xs md:text-lg font-bold font-sans bg-purple-600 p-3 rounded-md"
      >
        {loading
          ? 'Initializing smart account...'
          : walletAddress
              ? `${truncateEthAddress (walletAddress)}`
              : 'Connect Wallet'}
      </button>
      </div>
    </div>
  );
};

export default Header;
