import { ToastContainer } from 'react-toastify'
import Header from './components/Header'

import Lock from './components/Lock'
import Error from './components/Error'
import WalletProvider from './components/WalletContext'
import 'react-toastify/dist/ReactToastify.css'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AllLocks from './components/AllLocks'


const App = () => {
  return (
    <div className='bg-black overflow-auto h-screen'>
      <Router>
      <WalletProvider>
      <Header />
        <Routes>
          <Route path='/' element={<Lock />}/>
          <Route path='/locks' element={<AllLocks />}/>
          <Route path='*' element={<Error />}/>
        </Routes>
        <ToastContainer />
      </WalletProvider>
      </Router>
    </div>
  )
}

export default App
